@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
  url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
  url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
  url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

