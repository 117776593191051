@media (max-width: 1425px) {
  .container {
    padding: 0 30px !important;
  }

  header {
    .contacts {
      .phone {
        font-size: 20px !important;
      }
    }
  }

  .header-container {
    .header-main {
      margin-top: 140px !important;
    }

    .header-additional {
      .description {
        margin-top: 0 !important;
      }
    }
  }

  .horizontal-padding {
    padding: 0 50px;
  }
  .horizontal-margin {
    margin: 0 50px;
  }
}

@media (max-width: $xl) {
  h1 {
    font-size: 40px !important;
  }

  h2 {
    font-size: 35px !important;
  }

  header {
    .contacts {
      text-align: center;
    }
  }

  .why-container {
    .why-item {
      img {
        width: 70% !important;
      }
    }
  }
}

@media (max-width: $lg) {
  header {
    padding: 0 30px;
  }

  .horizontal-padding {
    padding: 0;
  }
  .horizontal-margin {
    margin: 0;
  }

  .header-container {
    .header-additional {
      margin-top: 0 !important;
      margin-bottom: 50px;

      .description {
        margin-top: 50px !important;
      }
    }
  }

  .header-images {
    padding-left: 0 !important;

    .img-secondary {
      margin-bottom: -130px !important;
      width: 100% !important;
    }
  }

  .why-container {
    > .title {
      padding-top: 70px;
    }

    .why-item {
      img {
        width: 65% !important;
      }
    }
  }

  .contacts-container {
    .advantage-item {
      min-height: 315px !important;
    }

    .info-container {
      padding-left: 0 !important;

      .map-container {
        margin-top: 54px;
      }
    }
  }

  #priceCheckModal, #serviceSignUpModal {
    .nav-tabs {
      width: 100% !important;
    }

    .tab-content {
      padding: 30px 50px !important;

      .btn-uniparts {
        margin-top: 30px !important;
      }

      .form-group {
        margin-bottom: 0 !important;
      }

      .tab-pane#finish {
        text-align: center;

        .title {
          margin-top: 20px;
        }

      }
    }
  }

  header {
    .contacts {
      text-align: center;
      margin-top: 50px !important;
      margin-bottom: -50px !important;
    }
  }
}
