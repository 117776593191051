@import "variables";
@import "fonts";

%default-title {
  font-size: 16px;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}

%default-large-title {
  font-size: 20px;
  font-weight: normal;
  display: block;
}

%default-description {
  font-size: 14px;
  font-weight: normal;
  display: block;
}

h1 {
  font-size: 54px;
  font-weight: bold;
  text-transform: uppercase;
}

h2 {
  font-size: 46px;
  font-weight: 600;
  text-transform: uppercase;
}

body {
  font-family: 'Proxima Nova', serif;
  color: #ffffff;
  background: url("../img/header-background.jpg") top center no-repeat #222222;
  background-size: 100%;
}

a {
  color: #ffffff;
  transition: color 0.3s;

  &:hover {
    color: $main-yellow;
    text-decoration: none;
  }
}

.bordered-10 {
  border-radius: 10px;
}

.horizontal-padding {
  padding: 0 96px;
}

.horizontal-margin {
  margin: 0 96px;
}

.black-background {
  background: #000000;
}

.accent {
  color: $main-yellow;
}

header {
  .logo {
    max-width: 300px;

    .logo-caption {
      font-size: 14px;
      font-weight: bold;
      color: $main-yellow;
      text-transform: uppercase;
      text-align: right;
      padding-right: 20px;
    }
  }

  .work-time {
    .text-uppercase {
      font-size: 20px;
    }
  }

  .contacts {
    i.fab {
      font-size: 24px;
      color: #C6C6C6;
    }

    .phone {
      font-size: 30px;
      font-weight: bold;
    }
  }
}

section#header {
  margin-top: 150px;

  .header-container {
    position: relative;
    top: -65px;
    padding: 0;

    .header {
      &-main {
        margin-top: 180px;

        .title {
          line-height: 1;
        }

        .subtitle {
          @extend %default-large-title;
          margin-top: 29px;
          text-transform: uppercase;
        }
      }

      &-additional {
        margin-top: 50px;

        .description {
          display: inline-block;
          font-size: 16px;
          font-weight: normal;
          margin: 70px 0 30px;
        }

        .img-additional {
          position: relative;
          bottom: -90px;
        }
      }

      &-images {
        .img {
          &-secondary {
            margin: 40px 0 -300px;
          }
        }
      }
    }
  }

  &.service {
    margin-bottom: 300px;
  }
}

section#why {
  background: #212121 url("../img/car.jpg") no-repeat top right;
  margin-top: 70px;
  padding-bottom: 450px;

  .why-container {
    h2.title {
      padding: 50px 0 70px;
    }

    .why-item {
      background-color: white !important;
      padding: 12px 20px;
      text-transform: uppercase;
      border-radius: 10px;
      position: relative;
      margin-bottom: 50px;

      .title {
        font-size: 30px;
        color: $main-yellow;
        display: block;
        font-weight: bold;
      }

      .description {
        @extend %default-title;
        color: black;
        max-width: 60%;
        padding: 10px 0;
      }

      img {
        width: 90%;
        position: absolute;
        top: -10px;
        right: 0;
      }
    }
  }
}

section#contacts {
  .contacts-container {
    position: relative;
    top: -180px;

    h2 {
      padding-bottom: 20px;
    }

    .advantage-container {
      .advantage-item {
        background-color: white;
        margin: 15px 0 50px;
        min-height: 280px;
        text-align: center;
        border-radius: 10px;
        padding: 15px 20px;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;

        .title {
          @extend %default-title;
          color: black;
          padding: 0 25px;
        }

        .subtitle {
          @extend %default-description;
          color: black;
          padding-top: 5px;
        }
      }
    }

    .info-container {
      .map-container {
        background-color: white;
        padding-bottom: 40px;
        border-radius: 10px;

        .info {
          padding: 40px 35px 0 35px;

          color: black;

          .title {
            @extend %default-title;
          }

          .subtitle {
            @extend %default-large-title;
            padding: 10px 0;
          }

          .description {
            @extend %default-description;
          }
        }
      }

      .price-container {
        margin-top: 54px;
        background-color: white;
        border-radius: 10px;
        color: black;
        padding: 30px 40px;

        .title {
          @extend %default-title;
        }

        .subtitle {
          display: block;
          font-size: 16px;
          text-transform: lowercase;
        }

        .btn-uniparts {
          margin-top: 20px;
        }
      }
    }
  }
}

footer{
  margin-top: -100px;
}

.btn-uniparts {
  border-radius: 10px;
  background-color: $btn-bg-color;
  color: $btn-text-color !important;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px;

  text-transform: uppercase;
  cursor: pointer;
}

.text {
  &-yellow {
    color: $main-yellow;
  }
}

#priceCheckModal, #serviceSignUpModal {
  .modal-header {
    img {
      height: 40px;
    }

    .modal-title {
      @extend %default-description;
      color: black;
      padding-left: 20px;
      padding-top: 10px;
    }
  }

  .modal-body {
    padding: 0;

    h2 {
      color: black;
      font-size: 40px;
    }

    .title {
      @extend %default-title;
      color: black;
    }

    .subtitle {
      @extend %default-large-title;
      padding: 10px 0;
      color: black;
    }

    .description {
      @extend %default-description;
      color: black;
    }

    img {
      border-radius: 10px;
    }

    nav {
      margin-bottom: 10px;
      background-color: $grey;

      .nav-tabs {
        font-size: 16px;
        font-weight: normal;
        width: 50%;
        margin: auto;
        border: none;

        .nav-link {
          color: black;
          border: none;

          &.active {
            color: black;
            background-color: transparent;
            border-bottom: 3px solid $main-yellow !important;
          }

          &.success {
            &:before {
              font-family: "Font Awesome\ 5 Free";
              font-weight: 900;
              content: "\f058";
              padding-right: 15px;
              color: $main-yellow;
            }
          }

          &.disabled {
            color: black;
            opacity: 0.5;
          }
        }
      }
    }

    .tab-content {
      padding: 40px 70px;
    }

    .btn-uniparts {
      min-width: 200px;

      &.outline {
        background-color: $btn-bg-outline-color;
        border: 2px solid $btn-bg-color;
      }
    }

    a {
      color: $main-yellow;
    }
  }

  .form-group {
    > label {
      color: black;
    }
  }
}

.select2-selection__rendered, .select2-selection, .selection {
  &:focus {
    outline: none !important;
  }
}

@import "adaptive";
